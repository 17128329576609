// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api : {
    domain : '127.0.0.1',
    port : '8081',
    protocol : 'http'
  },
  keycloak: {
    domain : '127.0.0.1',
    port : '8080',
    protocol : 'http'
  },
  keyCloakUser: {
    username: 'ldechamps',
    name: 'Loic DECHAMPS',
    email: 'loic@webcapsule.io',
  },
  production: false,
  mock : true,
  directusOnly : false,
  googleTagID : '',
  clarity : '',
  datadog : undefined
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { environment } from "src/environments/environment.mock";
import { cloudProviders } from "./cloudprovider";


// add api rules
const regionAWS = [
  { name : 'Europe - Paris',
    code : 'eu-west-3'
  },
  {
    name : 'Europe - Ireland',
    code : 'eu-west-1',
    workinprogress : !environment.mock
  },
  {
    name : 'Europe - London',
    code : 'eu-west-2',
    workinprogress : !environment.mock
  },
  {
    name : 'Europe - Stockholm',
    code : 'eu-north-1',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Europe - Frankfurt',
    code : 'eu-central-1',
    workinprogress : !environment.mock
  },
  {
    name : 'US West - N. California',
    code : 'us-west-1',
    workinprogress : !environment.mock
  },
  {
    name : 'US West - Oregon',
    code : 'us-west-2',
    workinprogress : !environment.mock
  },
  {
    name : 'US East - N. Virginia',
    code : 'eu-east-1',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'US East - Ohio',
    code : 'eu-east-2',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Asia Pacific - Mumbai',
    code : 'ap-south-1',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Asia Pacific - Tokyo',
    code : 'ap-northeast-1',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Asia Pacific - Seoul',
    code : 'ap-northeast-2',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Asia Pacific - Osaka',
    code : 'ap-northeast-3',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Asia Pacific - Singapore',
    code : 'ap-southeast-1',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Asia Pacific - Sydney',
    code : 'ap-southeast-2',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'Canada - Central',
    code : 'ca-central-1',
    workinprogress : !environment.mock
  }
  ,
  {
    name : 'South America - São Paulo',
    code : 'sa-east-1',
    workinprogress : !environment.mock
  }
]

const regionOVH = [
  { name : 'Europe - Gravelines 1',
    code : 'GRA1'
  }
]


const regionWBCE = regionAWS
const regionGCP = regionAWS


export const hostsConfig =  {
  [cloudProviders.AWS] : {
    name : 'AWS',
    icon : 'aws',
    region : regionAWS
  }, [cloudProviders.GCP] : {
    name : 'GCP',
    icon : 'gcp',
    region :regionGCP
  }, [cloudProviders.WBCE] : {
    name : 'Webcapsule',
    icon : 'wbce',
    region : regionWBCE
  }, [cloudProviders.OVH] : {
    name : 'OVH',
    icon : 'ovh',
    region : regionOVH
  }
}
